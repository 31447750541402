import React from 'react';
import styled from '@emotion/styled';

import Text from '../../Text';
import seamlessImage from '../../../assets/homepage/seamless-image.svg';
import DefaultGridSection from '../DefaultGridSection';
import { media } from '../../../utils/emotion';

const PreTextContainer = styled('div')`
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  font-family: monospace;
  padding: 1.8rem 1rem;
  background-color: ${({ theme }) => theme.colors.codeBlock.lightBackground};
  font-size: 1.6rem;
  line-height: 2.2rem;
  ${media.desktop`
    padding: 1.8rem 7rem;
  `}
`;

const SeamlessConnectedSection = () => {
  return (
    <DefaultGridSection
      image={seamlessImage}
      direction="imageOnLeft"
      gridLgProportions={['3fr', '4fr']}
      title="Organism is a living life form that will continuously evolve."
    >
      <Text>
      The internet exists solely between the confined lines of computers and our imagination. 
      It has evolved to become not only an extension of our society but also a new universe in its own right. 
      </Text>
      <Text>
        Access to technology has come to define our lives. 
        Its ever-evolving nature helps push society further by providing new capabilities.  
      </Text>
      <Text>
       These new capabilities requires strict security hygiene. We work together to tailor the right approach for needs.
      </Text>
      <Text>
        Security threat analysis for protecting personal safety. We know that having access to digital keys of high value caries a responsibility. Your personal safety
        is paramount for your well being. Let us help design a system so you sleep and travel with a piece of mind.
      </Text>
      <PreTextContainer>
      Our mission is to empower the individual & business with the tools they need to live in the digital universe
      </PreTextContainer>
    </DefaultGridSection>
  );
};

export default SeamlessConnectedSection;
